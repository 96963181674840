



















import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";

@Component({})
export default class Navbar extends Vue {
  @Prop(Boolean) private drawer!: boolean;

  private userModule: UserModule = getModule(UserModule);
  private loggingOut = false;

  private onLogout(): void {
    this.loggingOut = true;
    this.userModule.logout();
  }

  get getUserName(): string | null | undefined {
    return this.userModule.userName;
  }
}
