



















import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { SessionErrorLogModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class SessionErrorsLogTable extends Vue {
  @Prop(String) private sessionId!: string;
  private loading = false;
  private items: Array<SessionErrorLogModel> = [];
  private headers: Array<DataTableHeader<SessionErrorLogModel>> = [
    { text: "Unit", value: nameof<SessionErrorLogModel>("unit") },
    { text: "Error Code", value: nameof<SessionErrorLogModel>("errorCode") },
    { text: "Error Time", value: nameof<SessionErrorLogModel>("errorTime") },
    {
      text: "Error State",
      value: nameof<SessionErrorLogModel>("errorStateStyle"),
    },
  ];

  private async created() {
    this.getAllSessionErrors();
  }

  private async getAllSessionErrors() {
    try {
      const response = await api.SessionService.apiSessionErrorsSessionIdGet(
        this.sessionId
      );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Session Errors");
    }
  }
}
