









import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { KegYieldGraphModel } from "@/api/generated";
import api from "@/api";
import KegYieldsGraph from "@/components/graphs/KegYieldsGraph.vue";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { KegYieldsGraph } })
export default class GraphContainer extends Vue {
  @Prop(String) private readonly kioskSessionId!: string;

  private kegYields: Array<KegYieldGraphModel> = [];
  private mappedYieldsX: Array<string> = [];
  private mappedYieldsY: Array<number> = [];
  private KegDrinkCountLabels: Array<number> = [];
  private async created() {
    this.getKegYields();
  }

  private async getKegYields() {
    const response =
      await api.ReportingApi.apiReportingKegYieldKioskSessionIdGet(
        this.kioskSessionId
      );
    this.kegYields = response.data;
    this.mapKegYields();
    this.mapKegDrinkCount();
  }

  private async mapKegYields() {
    this.kegYields.forEach((keg) => {
      this.mappedYieldsX.push(`${keg.kegNumber} ${keg.side === 1 ? "L" : "R"}`);
      this.mappedYieldsY.push(Math.round(0));
    });
  }

  private async mapKegDrinkCount() {
    this.kegYields.forEach((keg) => {
      this.KegDrinkCountLabels.push(0);
    });
  }
}
