



























import { Component, Emit, Prop, PropSync } from "vue-property-decorator";
import Validation from "@/mixins/validation";

@Component
export default class UserDeleteDialog extends Validation {
  @Prop({ type: String, default: "Delete" }) private btnText!: string;
  @Prop({ type: String }) private msg!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;

  @Emit("delete")
  private onConfirm() {
    this.syncedDialog = false;
  }
}
