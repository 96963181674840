











































































































































































































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import draggable from "vuedraggable";
import api from "@/api";
import {
  KioskModel,
  SessionModel,
  BrandModel,
  KioskSessionModel,
  KioskSessionMultipleCreateModel,
  OperationMode,
  OperationModeConstantModel,
} from "@/api/generated";
import KioskSessionCard from "@/components/kioskSession/KioskSessionCard.vue";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";
import SessionErrorsLogTable from "@/components/session/SessionErrorsLogTable.vue";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: { draggable, KioskSessionCard, SessionErrorsLogTable },
})
export default class SessionProfile extends Vue {
  @Prop(String) private sessionId!: string;
  @Ref() private readonly form!: VForm;
  private loading = false;
  private startSessionDialog = false;
  private startKioskSessionDialog = false;
  private endSessionDialog = false;
  private endKioskSessionDialog = false;
  private selectedKioskId = "";
  private kiosks: Array<KioskModel> = [];
  private operationModes: Array<OperationModeConstantModel> = [];
  private kioskSessions: Array<KioskSessionModel> = [];
  private brands: Array<BrandModel> = [];
  private model: SessionModel = {
    brandId: "",
    deploymentId: "",
    name: "",
    drinkPrice: 0,
    maxOrder: 0,
    sessionId: "",
    brandName: "",
    deploymentName: "",
    startedUtc: "",
    closedUtc: "",
    kioskSessions: [],
    transactions: [],
    totalRevenue: 0.0,
    totalDrinksSold: 0,
  };

  private kioskIds: Array<string> = [];

  private async created() {
    this.getSessionById();
    this.getBrands();
    this.getKiosks();
    this.getKioskSessions();
    this.getOperationModes();
  }

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }

  private get adminOnsiteUserPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private async getSessionById() {
    const response = await api.SessionService.apiSessionSessionIdGet(
      this.sessionId
    );
    this.model = response.data;
  }

  private async getBrands() {
    const response = await api.BrandService.apiBrandGet();
    this.brands = response.data;
  }

  private async getKiosks() {
    const response = await api.KioskService.apiKioskAvailableSessionIdGet(
      this.sessionId
    );
    this.kiosks = response.data;
  }

  private async getOperationModes() {
    const response = await api.ConstantService.operationmodesGet();
    this.operationModes = response.data;
  }

  private async getKioskSessions() {
    const response =
      await api.KioskSessionService.apiKiosksessionSessionSessionIdGet(
        this.sessionId
      );
    this.kioskSessions = response.data;
  }

  private buttonClicked(dialogName: string, id: string) {
    this.selectedKioskId = id;
    if (dialogName == "startKioskSessionDialog") {
      this.startKioskSessionDialog = true;
    } else if (dialogName == "endKioskSessionDialog") {
      this.endKioskSessionDialog = true;
    }
  }

  private async createKioskSessions() {
    let createModel: KioskSessionMultipleCreateModel = {
      sessionId: this.sessionId,
      kioskIds: this.kioskIds,
    };
    try {
      await api.KioskSessionService.apiKiosksessionMultiplePost(createModel);
      this.kioskIds = [];
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create Kiosk Session");
    }
    this.getKiosks();
    this.getKioskSessions();
  }

  private async startSession() {
    await api.SessionService.apiSessionStartSessionIdPost(this.sessionId);
    this.getSessionById();
    this.getKioskSessions();
  }

  private async endSession() {
    await api.SessionService.apiSessionStopSessionIdPost(this.sessionId);
    this.getSessionById();
    this.getKioskSessions();
  }

  private async startKioskSession(id: string) {
    await api.KioskSessionService.apiKiosksessionStartKioskSessionIdPost(id);
    this.getSessionById();
    this.getKioskSessions();
  }

  private async endKioskSession(id: string) {
    await api.KioskSessionService.apiKiosksessionCloseKioskSessionIdPost(id);
    this.getSessionById();
    this.getKioskSessions();
  }
  private showProfile(item: KioskSessionModel) {
    if (item.kioskSessionId != null) {
      this.$router.push({
        name: "KioskSessionProfile",
        params: { kioskSessionId: item.kioskSessionId },
      });
    }
  }
}
