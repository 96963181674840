var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-table"},[_c('v-switch',{attrs:{"label":"Show Archived"},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}}),_c('base-data-table',{attrs:{"title":"Brands","headers":_vm.headers,"items":_vm.filteredBrands,"loading":_vm.loading},on:{"create-item":function($event){return _vm.$emit('create')}},scopedSlots:_vm._u([{key:"item.primaryColour",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.primaryColour}},[_vm._v("mdi-circle")])]}},{key:"item.secondaryColour",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.secondaryColour}},[_vm._v("mdi-circle")])]}},{key:"item.logoBlobStorageId",fn:function(ref){
var item = ref.item;
return [(item.logoBlobStorageId)?_c('a',{attrs:{"href":_vm.getLogoLink(item.logoBlobStorageId),"target":"_blank"}},[_vm._v(" Preview Logo ")]):_c('div',[_vm._v("N/A")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('update', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),(!item.archived)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('archive', item)}}},[_c('v-list-item-title',[_vm._v("Archive")])],1):_vm._e(),(item.archived)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('unarchive', item)}}},[_c('v-list-item-title',[_vm._v("Unarchive")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }