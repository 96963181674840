






























import { Vue, Component } from "vue-property-decorator";
import VenueTable from "@/components/venue/VenueTable.vue";
import VenueCreateDialog from "@/components/dialogs/venue/VenueCreateDialog.vue";
import VenueUpdateDialog from "@/components/dialogs/venue/VenueUpdateDialog.vue";
import { VenueModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { VenueTable, VenueCreateDialog, VenueUpdateDialog } })
export default class VenueOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private deleteDialog = false;
  private selectedVenueId = "";

  private onUpdateVenue(item: VenueModel) {
    if (!item.venueId) {
      return;
    }

    this.selectedVenueId = item.venueId;
    this.updateDialog = true;
  }

  private async archiveVenue(item: VenueModel) {
    if (!item.venueId) {
      return;
    }

    this.selectedVenueId = item.venueId;

    try {
      await api.VenueService.apiVenueArchiveVenueIdDelete(this.selectedVenueId);
      snackbarModule.setSnackbarMessage("Venue successfully archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive venue");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }

  private async unarchiveVenue(item: VenueModel) {
    if (!item.venueId) {
      return;
    }

    this.selectedVenueId = item.venueId;

    try {
      await api.VenueService.apiVenueUnarchiveVenueIdDelete(
        this.selectedVenueId
      );
      snackbarModule.setSnackbarMessage("Venue successfully unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive venue");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }

  private async onDeleteRequest(venue: VenueModel) {
    if (!venue.venueId) {
      return;
    }
    this.selectedVenueId = venue.venueId;
    this.deleteDialog = true;
  }

  private async onDeleteVenue() {
    if (!this.selectedVenueId) {
      return;
    }

    try {
      await api.VenueService.apiVenueVenueIdDelete(this.selectedVenueId);
      snackbarModule.setSnackbarMessage("Venue successfully deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete venue");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
