
















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  KioskModel,
  KioskSessionErrorsLogModel,
  KioskSessionModel,
  ManualKioskErrorLogModel,
} from "@/api/generated";
import EbarPastSessions from "@/components/session/SessionTable.vue";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import ManualErrorDialog from "@/components/dialogs/errors/ManualErrorDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { EbarPastSessions, ManualErrorDialog } })
export default class EbarProfile extends Vue {
  @Prop(String) private kioskId!: string;

  private loading = false;
  private resolveDialog = false;
  private totalTransactions = 0;
  private manualErrorDialog = false;
  private resolveManualErrorDialog = false;
  private dispenseLogs: Array<KioskSessionErrorsLogModel> = [];
  private flaggedLogs: Array<KioskSessionErrorsLogModel> = [];
  private manualErrorLogs: Array<ManualKioskErrorLogModel> = [];
  private manualErrorLogId = "";
  private flaggedErrorLog: KioskSessionErrorsLogModel = {
    errorCode: "",
  };
  private kiosk: KioskModel = {
    kioskId: "",
    kioskCode: "",
    machineIdentifier: "",
    createdUtc: "",
    deployed: false,
    terminalId: "",
  };
  private currentSession: KioskSessionModel = {
    kioskId: "",
    sessionId: "",
    sessionName: "",
    kioskSessionId: "",
    kioskKioskCode: "",
    sessionDeploymentName: "",
    sessionDeploymentVenueName: "",
    sessionDeploymentDeploymentId: "",
    createdUtc: "",
    startedUtc: "",
    closedUtc: "",
    transactions: [],
    kioskSessionStatuses: [],
  };
  private upcomingSessions: Array<KioskSessionModel> = [];
  private pastKioskSessions: Array<KioskSessionModel> = [];
  private upcomingSessionHeaders: Array<DataTableHeader<KioskSessionModel>> = [
    { text: "Deployment", value: "sessionDeploymentName" },
    { text: "Venue", value: "sessionDeploymentVenueName" },
    { text: "Session", value: "sessionName" },
  ];
  private pastSessionHeaders: Array<DataTableHeader<KioskSessionModel>> = [
    {
      text: "Deployment",
      value: "sessionDeploymentName",
    },
    { text: "Venue", value: "sessionDeploymentVenueName" },
    { text: "Session", value: "sessionName" },
    { text: "Started", value: "sessionDeploymentStartedUtc" },
  ];
  private dispenseLogHeaders: Array<
    DataTableHeader<KioskSessionErrorsLogModel>
  > = [
    {
      text: "Error Code",
      value: "errorCode",
    },
    { text: "Deployment", value: "kioskSessionSessionDeploymentName" },
    { text: "Error Time", value: "created" },
    { text: "State", value: "state" },
  ];
  private flaggedLogHeaders: Array<
    DataTableHeader<KioskSessionErrorsLogModel>
  > = [
    {
      text: "Error Code",
      value: "errorCode",
    },
    { text: "Frequency", value: "frequency" },
    { text: "State", value: "state" },
    { text: "", value: "actions", sortable: false },
  ];
  private manualLogHeaders: Array<DataTableHeader<ManualKioskErrorLogModel>> = [
    {
      text: "Error Code",
      value: "errorCode",
    },
    { text: "Description", value: "errorDescription" },
    { text: "Created", value: "created" },
    { text: "State", value: "state" },
    { text: "", value: "actions", sortable: false },
  ];

  private async created(): Promise<void> {
    await this.getKiosk();
    await this.getCurrentKioskSession();
    await this.getUpcomingKioskSession();
    await this.getPastKioskSessions();
    await this.getAllDispenseLogs();
    await this.getAllFlaggedLogs();
    await this.getAllManualErrorLogs();
  }

  private async getKiosk() {
    try {
      const response = await api.KioskService.apiKioskGetByIdKioskIdGet(
        this.kioskId
      );
      this.kiosk = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve kiosk information");
    }
  }

  private async getCurrentKioskSession() {
    try {
      const response = await api.KioskService.apiKioskActiveSessionKioskIdGet(
        this.kioskId
      );
      this.currentSession = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve current kiosk session"
      );
    }
  }

  private async getUpcomingKioskSession() {
    try {
      const response =
        await api.KioskService.apiKioskUpcomingSessionsKioskIdGet(this.kioskId);
      this.upcomingSessions = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve upcoming sessions");
    }
  }

  private async getPastKioskSessions() {
    try {
      const response = await api.KioskService.apiKioskPastSessionsKioskIdGet(
        this.kioskId
      );
      this.pastKioskSessions = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve past kiosk sessions"
      );
    }
  }

  private async getAllDispenseLogs() {
    try {
      this.loading = true;
      const response = await api.KioskService.apiKioskDispenseLogKioskIdGet(
        this.kioskId
      );
      this.dispenseLogs = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Dispense Logs");
    } finally {
      this.loading = false;
    }
  }

  private async getAllFlaggedLogs() {
    try {
      this.loading = true;
      const response = await api.KioskService.apiKioskFlaggedLogsKioskIdGet(
        this.kioskId
      );
      this.flaggedLogs = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Flagged Logs");
    } finally {
      this.loading = false;
    }
  }
  private async getAllManualErrorLogs() {
    try {
      this.loading = true;
      const response = await api.KioskService.apiKioskManualErrorsKioskIdGet(
        this.kioskId
      );
      this.manualErrorLogs = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Manual Error Logs");
    } finally {
      this.loading = false;
    }
  }

  private async onResolveErrors(item: KioskSessionErrorsLogModel) {
    this.flaggedErrorLog = item;
    this.resolveDialog = true;
  }

  private async onResolveManualErrors(item: ManualKioskErrorLogModel) {
    if (item.manualKioskErrorLogId) {
      this.manualErrorLogId = item.manualKioskErrorLogId;
    }
    console.log(this.manualErrorLogId);
    this.resolveManualErrorDialog = true;
  }

  private async resolveErrors() {
    try {
      await api.KioskService.apiKioskResolveErrorKioskIdPut(
        this.kioskId,
        this.flaggedErrorLog
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to resolve error");
    } finally {
      this.getKiosk();
      this.getAllFlaggedLogs();
      this.resolveDialog = false;
    }
  }

  private async resolveManualError() {
    try {
      await api.KioskService.apiKioskResolveManualErrorManualErrorLogIdPut(
        this.manualErrorLogId
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to resolve error");
    } finally {
      this.getKiosk();
      this.getAllManualErrorLogs();
      this.resolveManualErrorDialog = false;
    }
  }

  private showCurrentDepolyment() {
    const deploymentId = this.currentSession.sessionDeploymentDeploymentId;
    this.$router.push({
      name: "DeploymentProfile",
      params: { deploymentId: deploymentId! },
    });
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "DeploymentProfile",
      params: { deploymentId: item.sessionDeploymentDeploymentId },
    });
  }
}
