















import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import Sidebar from "@/components/layout/Sidebar.vue";
import Navbar from "@/components/layout/Navbar.vue";
import TheSnackbar from "@/components/common/TheSnackbar.vue";
import environment from "./environment";

const userModule = getModule(UserModule);

@Component({ components: { TheSnackbar, Sidebar, Navbar } })
export default class App extends Vue {
  private drawer = true;

  get showTestWarning(): boolean {
    return environment.SHOW_TEST_WARNING;
  }

  get getAuthorized(): boolean {
    return userModule.loggedIn;
  }
}
