































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import api from "@/api";
import nameof from "@/utility/nameof";
import { DataTableHeader } from "vuetify";
import {
  SessionModel,
  KioskSessionModel,
  BrandModel,
  FilterModel,
  PintsPerHourGraphModel,
} from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import DeploymentPintsPerHourGraph from "@/components/graphs/DeploymentPintsPerHourGraph.vue";
import reportingService from "@/services/reportingService";
import { PintsPerHourGraphDataType } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { DeploymentPintsPerHourGraph } })
export default class DeploymentReports extends Vue {
  @Prop(String) private deploymentId!: string;
  private loading = false;
  private filterModel: FilterModel = {
    sessionIds: [],
    brandIds: [],
    kioskSessionIds: [],
  };
  private sessions: Array<SessionModel> = [];
  private kioskSessions: Array<KioskSessionModel> = [];
  private pintsPerHour: Array<Array<PintsPerHourGraphModel>> = [];
  private brands: Array<BrandModel> = [];
  private headers: Array<DataTableHeader<SessionModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<SessionModel>("name") },
  ];
  pintsPerHourGraphDataType = PintsPerHourGraphDataType;
  private radioGroup = "interval";

  private async created() {
    await this.getDeploymentPintsPerHour(
      this.filterModel,
      this.pintsPerHourGraphDataType.NUMBER_0
    );
    await this.getSessions();
    await this.getKioskSessionsByDeployment();
    await this.getDeploymentBrands();
  }

  private async getDeploymentPintsPerHour(
    filterModel: FilterModel,
    dataType: PintsPerHourGraphDataType
  ) {
    try {
      this.loading = true;
      const response =
        await api.ReportingApi.apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(
          this.deploymentId,
          dataType,
          this.radioGroup,
          filterModel
        );
      this.pintsPerHour = response.data;
    } finally {
      this.loading = false;
    }
  }

  private async getSessions() {
    this.loading = true;
    try {
      const response =
        await api.SessionService.apiSessionBydeploymentDeploymentIdGet(
          this.deploymentId
        );
      this.sessions = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve deployments");
    } finally {
      this.loading = false;
    }
  }

  private async getKioskSessionsByDeployment() {
    try {
      const response =
        await api.KioskSessionService.apiKiosksessionDeploymentDeploymentIdGet(
          this.deploymentId
        );
      this.kioskSessions = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve deployment kiosk sessions"
      );
    }
  }

  private async getDeploymentBrands() {
    try {
      const response = await api.BrandService.apiBrandDeploymentDeploymentIdGet(
        this.deploymentId
      );
      this.brands = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve deployment brands");
    }
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "SessionReports",
      params: { sessionId: item.sessionId },
    });
  }

  public sessionClickedFirst = false;
  public brandClickedFirst = false;
  public unitClickedFirst = false;

  //#region Session Filter Section click methods

  private sessionClicked(sessionId: string) {
    this.sessionClickedFirst =
      !this.brandClickedFirst && !this.unitClickedFirst;

    const sessionChecked: boolean | undefined =
      this.filterModel.sessionIds?.includes(sessionId);

    const session: SessionModel | undefined = this.sessions.find(
      (session) => session.sessionId == sessionId
    );

    if (this.sessionClickedFirst) {
      if (session) {
        const brandId: string = session.brandId!;
        const kioskSessionIds: string[] | undefined = this.kioskSessions
          .filter((kioskSession) => kioskSession.sessionId === sessionId)
          ?.map((kioskSession) => kioskSession.kioskSessionId!);

        if (sessionChecked) {
          this.autoCheckRelatedSessionBrands(brandId);
          this.autoCheckRelatedSessionKiosks(kioskSessionIds);
        } else {
          this.autoUncheckRelatedSessionBrands(brandId);
          this.autoUncheckRelatedSessionKiosks(kioskSessionIds);

          this.sessionClickedFirst =
            this.filterModel.sessionIds !== null &&
            this.filterModel.sessionIds !== undefined &&
            this.filterModel.sessionIds.length > 0;
        }
      }
    }

    this.resetBooleans();
  }

  private autoCheckRelatedSessionBrands(brandId: string) {
    if (
      this.filterModel.brandIds &&
      !this.filterModel.brandIds.includes(brandId)
    ) {
      this.filterModel.brandIds.push(brandId);
    }
  }

  private autoCheckRelatedSessionKiosks(kioskSessionIds: string[]) {
    if (kioskSessionIds && this.filterModel.kioskSessionIds) {
      for (
        let kioskSessionIndex = 0;
        kioskSessionIndex < kioskSessionIds.length;
        kioskSessionIndex++
      ) {
        if (
          !this.filterModel.kioskSessionIds.includes(
            kioskSessionIds[kioskSessionIndex]
          )
        ) {
          this.filterModel.kioskSessionIds.push(
            kioskSessionIds[kioskSessionIndex]
          );
        }
      }
    }
  }

  private autoUncheckRelatedSessionBrands(brandId: string) {
    if (
      this.filterModel.brandIds &&
      this.filterModel.brandIds.includes(brandId)
    ) {
      const index = this.filterModel.brandIds.indexOf(brandId, 0);
      if (index > -1) {
        this.filterModel.brandIds.splice(index, 1);
      }
    }
  }

  private autoUncheckRelatedSessionKiosks(kioskSessionIds: string[]) {
    if (kioskSessionIds && this.filterModel.kioskSessionIds) {
      for (
        let kioskSessionIndex = 0;
        kioskSessionIndex < kioskSessionIds.length;
        kioskSessionIndex++
      ) {
        if (
          this.filterModel.kioskSessionIds.includes(
            kioskSessionIds[kioskSessionIndex]
          )
        ) {
          const spliceIndex = this.filterModel.kioskSessionIds.indexOf(
            kioskSessionIds[kioskSessionIndex],
            0
          );
          if (spliceIndex > -1) {
            this.filterModel.kioskSessionIds.splice(spliceIndex, 1);
          }
        }
      }
    }
  }

  //#endregion

  //#region Brand Filter Section click methods

  private brandClicked(brandId: string) {
    this.brandClickedFirst =
      !this.sessionClickedFirst && !this.unitClickedFirst;

    const brandChecked: boolean | undefined =
      this.filterModel.brandIds?.includes(brandId);

    const brand: BrandModel | undefined = this.brands.find(
      (brand) => brand.brandId == brandId
    );

    const sessionIds: string[] | undefined = this.sessions
      .filter((session) => session.brandId === brandId)
      ?.map((session) => session.sessionId!);

    if (this.brandClickedFirst) {
      if (brand) {
        if (brandChecked) {
          this.autoCheckRelatedBrandSessions(sessionIds);
        } else {
          if (sessionIds) {
            this.autoUncheckRelatedBrandSessions(sessionIds);
            this.autoUncheckRelatedBrandSessionsKiosks(sessionIds);
          }

          this.brandClickedFirst =
            this.filterModel.brandIds !== null &&
            this.filterModel.brandIds !== undefined &&
            this.filterModel.brandIds.length > 0;
        }
      }
    }

    this.resetBooleans();
  }

  private autoCheckRelatedBrandSessions(sessionIds: string[]) {
    if (sessionIds) {
      for (let index = 0; index < sessionIds.length; index++) {
        if (this.filterModel.sessionIds) {
          if (!this.filterModel.sessionIds.includes(sessionIds[index])) {
            this.filterModel.sessionIds?.push(sessionIds[index]);
          }
        }
      }

      this.autoCheckRelatedBrandSessionsKiosks(sessionIds);
    }
  }

  private autoCheckRelatedBrandSessionsKiosks(sessionIds: string[]) {
    const unitIds: string[] | undefined = this.kioskSessions
      .filter((kioskSession) => sessionIds.includes(kioskSession.sessionId))
      ?.map((m) => m.kioskSessionId!);

    if (unitIds && unitIds.length > 0) {
      for (let unitIndex = 0; unitIndex < unitIds.length; unitIndex++) {
        if (!this.filterModel.kioskSessionIds?.includes(unitIds[unitIndex])) {
          this.filterModel.kioskSessionIds?.push(unitIds[unitIndex]);
        }
      }
    }
  }

  private autoUncheckRelatedBrandSessions(sessionIds: string[]) {
    for (let index = 0; index < sessionIds.length; index++) {
      if (this.filterModel.sessionIds) {
        if (this.filterModel.sessionIds.includes(sessionIds[index])) {
          const spliceIndex = this.filterModel.sessionIds.indexOf(
            sessionIds[index],
            0
          );
          if (spliceIndex > -1) {
            this.filterModel.sessionIds.splice(spliceIndex, 1);
          }
        }
      }
    }
  }

  private autoUncheckRelatedBrandSessionsKiosks(sessionIds: string[]) {
    const unitIds: string[] | undefined = this.kioskSessions
      .filter((kioskSession) => sessionIds.includes(kioskSession.sessionId))
      ?.map((m) => m.kioskSessionId!);

    if (unitIds && unitIds.length > 0) {
      for (let unitIndex = 0; unitIndex < unitIds.length; unitIndex++) {
        if (this.filterModel.kioskSessionIds?.includes(unitIds[unitIndex])) {
          const unitSpliceIndex = this.filterModel.kioskSessionIds.indexOf(
            unitIds[unitIndex],
            0
          );

          if (unitSpliceIndex > -1) {
            this.filterModel.kioskSessionIds?.splice(unitSpliceIndex, 1);
          }
        }
      }
    }
  }

  //#endregion

  //#region KioskSession Filter Section click methods

  private unitClicked(kioskSessionId: string) {
    this.unitClickedFirst =
      !this.sessionClickedFirst && !this.brandClickedFirst;

    const kioskSession: KioskSessionModel = this.kioskSessions.find(
      (kioskSession) => kioskSession.kioskSessionId === kioskSessionId
    )!;

    const unitChecked: boolean | undefined =
      this.filterModel.kioskSessionIds?.includes(kioskSessionId);

    if (!unitChecked) {
      const parentSession: SessionModel = this.sessions.find(
        (session) => session.sessionId === kioskSession.sessionId
      )!;

      this.autoCheckRelatedKioskSessions(parentSession, kioskSessionId);
    } else {
      if (this.unitClickedFirst) {
        this.autoUncheckRelatedKioskSessions(kioskSession);
      } else {
        const spliceIndex =
          this.filterModel.kioskSessionIds?.indexOf(kioskSessionId);
        if (spliceIndex && spliceIndex > -1) {
          this.filterModel.kioskSessionIds?.splice(spliceIndex, 1);
        }
      }
    }

    this.resetBooleans();
  }

  private autoCheckRelatedKioskSessionBrands(parentSession: SessionModel) {
    const sessionBrandId: string = parentSession.brandId!;
    const brandSessionIds: string[] | undefined = this.sessions
      .filter(
        (session) =>
          session.sessionId !== parentSession.sessionId! &&
          session.brandId === sessionBrandId
      )
      ?.map((session) => session.sessionId!);

    const brandHasOtherSelectedSessions = brandSessionIds?.some(
      (brandSessionId) => this.filterModel.sessionIds?.includes(brandSessionId)
    );

    if (
      brandHasOtherSelectedSessions === undefined ||
      !brandHasOtherSelectedSessions
    ) {
      if (this.filterModel.brandIds?.includes(sessionBrandId)) {
        const brandIndex = this.filterModel.brandIds.indexOf(sessionBrandId, 0);
        if (brandIndex > -1) {
          this.filterModel.brandIds.splice(brandIndex, 1);
        }
      }
    }
  }

  private autoCheckRelatedKioskSessions(
    parentSession: SessionModel,
    kioskSessionId: string
  ) {
    const sameSessionUnits: KioskSessionModel[] | undefined =
      this.kioskSessions.filter(
        (kioskSession) =>
          kioskSession.kioskSessionId !== kioskSessionId &&
          kioskSession.sessionId === parentSession.sessionId &&
          this.filterModel.kioskSessionIds?.includes(
            kioskSession.kioskSessionId!
          )
      );
    if (sameSessionUnits === undefined || sameSessionUnits.length === 0) {
      if (this.filterModel.sessionIds?.includes(parentSession.sessionId!)) {
        const spliceIndex = this.filterModel.sessionIds.indexOf(
          parentSession.sessionId!,
          0
        );
        if (spliceIndex > -1) {
          this.filterModel.sessionIds.splice(spliceIndex, 1);
        }
      }

      this.autoCheckRelatedKioskSessionBrands(parentSession);
    }
  }

  private autoUncheckRelatedKioskSessions(kioskSession: KioskSessionModel) {
    const unitSessions: SessionModel[] | undefined = this.sessions.filter(
      (session) => session.sessionId === kioskSession.sessionId
    );

    if (unitSessions && unitSessions.length > 0) {
      for (
        let unitSessionIndex = 0;
        unitSessionIndex < unitSessions.length;
        unitSessionIndex++
      ) {
        if (
          !this.filterModel.sessionIds?.includes(
            unitSessions[unitSessionIndex].sessionId!
          )
        ) {
          this.filterModel.sessionIds?.push(
            unitSessions[unitSessionIndex].sessionId!
          );
        }
      }

      const unitSessionBrandIds: string[] = unitSessions.map((m) => m.brandId!);

      if (unitSessionBrandIds && unitSessionBrandIds.length > 0) {
        const brandUnitSessions: BrandModel[] | undefined = this.brands.filter(
          (brand) => unitSessionBrandIds.includes(brand.brandId!)
        );

        if (brandUnitSessions && brandUnitSessions.length) {
          for (
            let brandUnitSessionIndex = 0;
            brandUnitSessionIndex < brandUnitSessions.length;
            brandUnitSessionIndex++
          ) {
            if (
              !this.filterModel.brandIds?.includes(
                brandUnitSessions[brandUnitSessionIndex].brandId!
              )
            ) {
              this.filterModel.brandIds?.push(
                brandUnitSessions[brandUnitSessionIndex].brandId!
              );
            }
          }
        }
      }
    }
  }

  //#endregion

  //#region General Filters and Graph Methods

  private resetBooleans() {
    this.unitClickedFirst =
      this.filterModel.kioskSessionIds === undefined ||
      this.filterModel.kioskSessionIds?.length === 0
        ? false
        : this.unitClickedFirst;
    this.sessionClickedFirst =
      this.filterModel.sessionIds === undefined ||
      this.filterModel.sessionIds?.length === 0
        ? false
        : this.sessionClickedFirst;
    this.brandClickedFirst =
      this.filterModel.brandIds === undefined ||
      this.filterModel.brandIds?.length === 0
        ? false
        : this.brandClickedFirst;
  }

  get dataType(): PintsPerHourGraphDataType {
    return this.sessionClickedFirst
      ? this.pintsPerHourGraphDataType.NUMBER_1
      : this.brandClickedFirst
      ? this.pintsPerHourGraphDataType.NUMBER_2
      : this.unitClickedFirst
      ? this.pintsPerHourGraphDataType.NUMBER_3
      : this.pintsPerHourGraphDataType.NUMBER_0;
  }

  private async applyFilters() {
    await this.getDeploymentPintsPerHour(this.filterModel, this.dataType);
  }

  get graphTitle(): string {
    return this.sessionClickedFirst
      ? "by Session"
      : this.brandClickedFirst
      ? "by Brand"
      : this.unitClickedFirst
      ? "by Unit"
      : "";
  }

  //#endregion
}
