var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',[_c('div',[_c('base-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Kiosk: "+_vm._s(_vm.kiosk.kioskCode)+" ")]},proxy:true},{key:"subTitle",fn:function(){return [_vm._v(" Commissioned: "+_vm._s(_vm._f("formatDate")(_vm.kiosk.createdUtc))+" TID: "+_vm._s(_vm.kiosk.terminalId)+" ")]},proxy:true},{key:"text",fn:function(){return [_c('p',[_c('b',[_vm._v(" Client:")]),_vm._v(" "+_vm._s(_vm.currentSession.sessionDeploymentOrganisationName)+" ")]),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showCurrentDepolyment()}}},[_c('b',[_vm._v("Deployed at: ")]),_vm._v(_vm._s(_vm.currentSession.sessionDeploymentName)+" ")]),_c('p',[_c('b',[_vm._v("Current Session: ")]),_vm._v(_vm._s(_vm.currentSession.sessionName))]),_c('v-row',[_c('v-col',[_c('p',[_c('b',[_vm._v("Flagged Errors:")]),_vm._v(" "+_vm._s(_vm.kiosk.errorCount))])]),_c('v-col',[_c('p',[_c('b',[_vm._v("Error State:")]),_c('v-icon',{class:_vm.kiosk.errorState})],1)])],1)]},proxy:true}])})],1)]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_c('base-data-table',{attrs:{"title":"Upcoming Deployments","noCreate":"","headers":_vm.upcomingSessionHeaders,"items":_vm.upcomingSessions,"loading":_vm.loading},on:{"click:row":_vm.showProfile}})],1)])],1),_c('div',{staticClass:"ma-4"},[_c('base-data-table',{attrs:{"title":"Past Deployments","noCreate":"","headers":_vm.pastSessionHeaders,"items":_vm.pastKioskSessions,"loading":_vm.loading},on:{"click:row":_vm.showProfile},scopedSlots:_vm._u([{key:"item.sessionDeploymentStartedUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTime")(item.sessionDeploymentStartedUtc))+" ")]}}])})],1),_c('div',{staticClass:"ma-4"},[_c('base-data-table',{attrs:{"title":"Flagged Error Log","headers":_vm.flaggedLogHeaders,"items":_vm.flaggedLogs,"loading":_vm.loading,"noCreate":""},scopedSlots:_vm._u([{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frequency)+"% ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.stateStyle})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('base-primary-btn',{on:{"click":function($event){return _vm.onResolveErrors(item)}}},[_vm._v("Resolve")])]}}])})],1),_c('div',{staticClass:"ma-4"},[_c('base-data-table',{attrs:{"title":"Manual Error Log","headers":_vm.manualLogHeaders,"items":_vm.manualErrorLogs,"loading":_vm.loading},on:{"create-item":function($event){_vm.manualErrorDialog = true}},scopedSlots:_vm._u([{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frequency)+"% ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.stateStyle})]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTime")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('base-primary-btn',{on:{"click":function($event){return _vm.onResolveManualErrors(item)}}},[_vm._v("Resolve")])]}}])})],1),_c('div',{staticClass:"ma-4"},[_c('base-data-table',{attrs:{"title":"Dispense Log","noCreate":"","headers":_vm.dispenseLogHeaders,"items":_vm.dispenseLogs,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.stateStyle})]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTime")(item.created))+" ")]}}])})],1),_c('manual-error-dialog',{attrs:{"dialog":_vm.manualErrorDialog,"kioskId":_vm.kioskId},on:{"update:dialog":function($event){_vm.manualErrorDialog=$event},"created":_vm.getAllManualErrorLogs}}),_c('base-confirmation-dialog',{attrs:{"dialog":_vm.resolveDialog,"title":"Are you sure that you want to resolve this error?","btnText":"Resolve"},on:{"update:dialog":function($event){_vm.resolveDialog=$event},"confirm":_vm.resolveErrors}}),_c('base-confirmation-dialog',{attrs:{"dialog":_vm.resolveManualErrorDialog,"title":"Are you sure that you want to resolve this error?","btnText":"Resolve"},on:{"update:dialog":function($event){_vm.resolveManualErrorDialog=$event},"confirm":_vm.resolveManualError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }