
















import { Vue, Component, Prop } from "vue-property-decorator";
import api from "@/api";
import { KioskSessionErrorsLogModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class KioskSessionErrorsTable extends Vue {
  @Prop({ type: String }) private kioskSessionId!: string;
  private loading = false;
  private selectedItem = "";
  private items: Array<KioskSessionErrorsLogModel> = [];
  private headers: Array<DataTableHeader<KioskSessionErrorsLogModel>> = [
    {
      text: "Error Code",
      value: nameof<KioskSessionErrorsLogModel>("errorCode"),
    },
    {
      text: "Frequency",
      value: nameof<KioskSessionErrorsLogModel>("frequency"),
    },
    { text: "State", value: nameof<KioskSessionErrorsLogModel>("state") },
  ];
  private async created() {
    await this.getKioskErrors();
  }

  private async getKioskErrors() {
    try {
      this.loading = true;
      const response =
        await api.KioskSessionErrorsLogApi.apiKiosksessionerrorslogErrorsKioskSessionIdGet(
          this.kioskSessionId
        );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve Kiosk Session Errors"
      );
    } finally {
      this.loading = false;
    }
  }

  private showProfile(item: any, row: any) {
    row.select(true);
    this.$router.push({
      name: "EbarProfile",
      params: { kioskId: item.kioskId },
    });
  }
}
