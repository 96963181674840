

























import { Vue, Component } from "vue-property-decorator";
import api from "@/api";
import nameof from "@/utility/nameof";
import { DataTableHeader } from "vuetify";
import { DeploymentModel, DeploymentOverviewModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: {} })
export default class ReportsOverview extends Vue {
  private loading = false;
  private deployments: Array<DeploymentOverviewModel> = [];
  private headers: Array<DataTableHeader<DeploymentOverviewModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<DeploymentOverviewModel>("name") },
    { text: "Venue", value: nameof<DeploymentOverviewModel>("venueName") },
    {
      text: "Organisation",
      value: nameof<DeploymentOverviewModel>("organisationName"),
    },
    { text: "Sales", value: nameof<DeploymentOverviewModel>("grossRevenue") },
    {
      text: "Started",
      value: "startedUtc",
    },
    {
      text: "Ended",
      value: "closedUtc",
    },
  ];

  private async created() {
    await this.getDeployments();
  }

  private async getDeployments() {
    this.loading = true;
    try {
      const response = await api.DeploymentService.apiDeploymentReportsGet();
      this.deployments = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve deployments");
    } finally {
      this.loading = false;
    }
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "DeploymentReports",
      params: { deploymentId: item.deploymentId },
    });
  }
}
