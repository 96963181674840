


















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class BaseTextarea extends Vue {
  @Prop(String) readonly topLabel!: string;
}
