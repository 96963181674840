


















































































import api from "@/api";
import { BrandModel } from "@/api/generated";
import environment from "@/environment";
import Validation from "@/mixins/validation";
import { apiClient } from "@/services/apiService";
import SnackbarModule from "@/store/snackbarModule";
import { Component, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class BrandEdit extends Validation {
  @Prop(String) private brandId!: string;
  @Ref() private uploader!: HTMLInputElement;
  private model: BrandModel = {
    name: "",
  };
  private loading = false;

  private get logoLink() {
    return `${environment.API_URL}/api/brand/logo/${this.model.logoBlobStorageId}`;
  }

  private async created() {
    const response = await api.BrandService.apiBrandBrandIdGet(this.brandId);
    this.model = response.data;
  }

  private pickImage() {
    this.uploader.click();
  }

  private async onSave() {
    try {
      this.loading = true;
      await api.BrandService.apiBrandBrandIdPut(this.brandId, this.model);
      snackbarModule.setSnackbarMessage("Brand saved");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to save brand");
    } finally {
      this.loading = false;
      this.$router.push({
        name: "Brand Overview",
      });
    }
  }

  private async onUploadBrandImage(e: any) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(
      `api/brand/${this.model.brandId}/logo`,
      formData
    );

    this.model.logoBlobStorageId = response.data!;

    snackbarModule.setSnackbarMessage("Logo updated");
  }
}
