











import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class TheSnackbar extends Vue {
  get computedShowSnackbar(): boolean {
    return snackbarModule.showSnackbar;
  }

  set computedShowSnackbar(value: boolean) {
    if (!value) {
      this.hideSnackbar();
    }
  }

  get computedSnackbarMessage(): string | null {
    return snackbarModule.snackbarMessage;
  }

  hideSnackbar(): void {
    snackbarModule.setSnackbarMessage("");
  }
}
