import { render, staticRenderFns } from "./BaseColorPicker.vue?vue&type=template&id=2f72437a&scoped=true&"
import script from "./BaseColorPicker.vue?vue&type=script&lang=ts&"
export * from "./BaseColorPicker.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f72437a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
installComponents(component, {VColorPicker})
