




















































import { Component, Ref } from "vue-property-decorator";
import { AuthorisationModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import UserModule from "@/store/userModule";
import AnonymousPasswordResetDialog from "@/components/dialogs/password/AnonymousPasswordResetDialog.vue";

const userModule = getModule(UserModule);

@Component({ components: { AnonymousPasswordResetDialog } })
export default class Login extends Validation {
  @Ref() readonly form!: VForm;

  private dialog = false;
  private loading = false;
  private model: AuthorisationModel = {
    emailAddress: "",
    password: "",
  };

  private async onSignIn() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await userModule.login(this.model);
      this.$router.replace({ name: "Dashboard" });
    } finally {
      this.loading = false;
    }
  }
}
