


































































































































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import SessionTable from "@/components/session/SessionTable.vue";
import { DeploymentModel, VenueModel } from "@/api/generated";
import CreateSessionDialog from "@/components/dialogs/session/CreateSessionDialog.vue";
import UpdateSessionDialog from "@/components/dialogs/session/UpdateSessionDialog.vue";
import { getModule } from "vuex-module-decorators";
import { SessionModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";
import api from "@/api";
import moment from "moment";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { CreateSessionDialog, SessionTable, UpdateSessionDialog },
})
export default class DeploymentProfile extends Vue {
  @Prop(String) private deploymentId!: string;
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private deleteDialog = false;
  private selectedSessionId = "";
  private venues: Array<VenueModel> = [];
  private model: DeploymentModel = {
    name: "",
  };

  private async created() {
    this.getDeploymentById();
    this.getVenues();
  }

  private async getDeploymentById() {
    const response = await api.DeploymentService.apiDeploymentDeploymentIdGet(
      this.deploymentId
    );
    this.model = response.data;
  }

  private async getVenues() {
    const response = await api.VenueService.apiVenueGet();
    this.venues = response.data;
  }

  private onUpdateSession(item: SessionModel) {
    if (!item.sessionId) {
      return;
    }

    this.selectedSessionId = item.sessionId;
    this.updateDialog = true;
  }

  private async onDeleteRequest(session: SessionModel) {
    if (!session.sessionId) {
      return;
    }
    this.selectedSessionId = session.sessionId;
    this.deleteDialog = true;
  }

  private async onDeleteSession() {
    if (!this.selectedSessionId) {
      return;
    }
    try {
      await api.SessionService.apiSessionSessionIdDelete(
        this.selectedSessionId
      );
      snackbarModule.setSnackbarMessage(`Session successfully deleted`);
      this.tableRefreshKey = !this.tableRefreshKey;
      this.$emit("deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete session");
    }
  }

  private get scheduledStartLocal() {
    const start = moment.utc(this.model.scheduledStart).local();
    return start;
  }

  private get scheduledEndLocal() {
    const end = moment.utc(this.model.scheduledEnd).local();
    return end;
  }
}
