


















































































import api from "@/api";
import {
  UserCreateModel,
  ConstantModel,
  OrganisationModel,
} from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class UserCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  private orgnanisationId = "";
  private loading = false;
  private userRoles: Array<ConstantModel> = [];
  private organisations: Array<OrganisationModel> = [];
  private model: UserCreateModel = {
    firstname: "",
    lastname: "",
    emailAddress: "",
    organisationId: "",
    role: "",
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }

  private async created() {
    this.getUserRoles();
    this.getOrganisations();
  }

  private async getUserRoles() {
    const response = await api.ConstantService.userrolesGet();
    this.userRoles = response.data;
  }

  get getFilteredUserRoles() {
    if (this.userRoles.length === 0) {
      return [];
    }

    const roleFilters = {
      [userRoleConstant.porfolioManager]: [
        userRoleConstant.porfolioManager,
        userRoleConstant.venueManager,
      ],
      [userRoleConstant.customerAdmin]: [
        userRoleConstant.porfolioManager,
        userRoleConstant.venueManager,
        userRoleConstant.customerAdmin,
      ],
    };

    const allowedRoles = roleFilters[userModule.userRole];
    // If allowed roles are defined, filter the user roles accordingly
    if (allowedRoles) {
      return this.userRoles.filter((role) =>
        allowedRoles.includes(role.value as string)
      );
    }
    // If no specific role filters are defined, return all user roles
    return this.userRoles;
  }

  private async getOrganisations() {
    const response = await api.OrganisationService.apiOrganisationGet();
    this.organisations = response.data;
  }

  private async onUserCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      if (
        [
          userRoleConstant.porfolioManager,
          userRoleConstant.customerAdmin,
        ].includes(userModule.userRole)
      ) {
        this.model.organisationId = userModule.organisationId;
      }
      await api.UserService.apiUserPost(this.model);
      snackbarModule.setSnackbarMessage("User created");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create user");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
