





























import { TransactionModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import environment from "@/environment";
import SnackbarModule from "@/store/snackbarModule";
import momentUtility from "@/utility/momentUtility";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class TransactionTable extends Vue {
  @Prop(String) private kioskSessionId!: string;
  private loading = false;
  private emailDialog = false;
  private search = "";
  private items: Array<TransactionModel> = [];
  private headers: Array<DataTableHeader<TransactionModel>> = [
    {
      text: "Ordered Drinks",
      value: nameof<TransactionModel>("orderedDrinks"),
    },
    {
      text: "Transaction Result",
      value: nameof<TransactionModel>("transactionResult"),
    },
    {
      text: "Transaction Reference",
      value: nameof<TransactionModel>("transactionReference"),
    },
    { text: "Used Credits", value: nameof<TransactionModel>("usedCredits") },
    {
      text: "Receipt Number",
      value: nameof<TransactionModel>("receiptNumber"),
    },
    { text: "Payment Card", value: nameof<TransactionModel>("paymentCard") },
    {
      text: "Transaction Time",
      value: nameof<TransactionModel>("createdUtc"),
    },
  ];

  private async created() {
    this.getTransactionsByKioskSessionId();
  }

  private async generateReceipt(item: TransactionModel) {
    window.location.href = `${environment.API_URL}/api/transaction/receipt/${item.transactionId}`;
  }

  private async getTransactionsByKioskSessionId() {
    try {
      this.loading = true;
      const response =
        await api.TransactionService.apiTransactionKiosksessionKiosksessionIdGet(
          this.kioskSessionId
        );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve transactions");
    } finally {
      this.loading = false;
    }
  }
}
