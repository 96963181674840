






import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { KegYieldGraphModel } from "@/api/generated";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class KegYieldsGraph extends Vue {
  @Prop(Array) private readonly graphDataX: Array<any> = [];
  @Prop(Array) private readonly graphDataY: Array<number> = [];
  @Prop(Array) private readonly graphLabels: Array<number> = [];

  private get series() {
    return [
      {
        name: "Yield %",
        data: this.graphDataY,
      },
      {
        name: "Pints Sold",
        data: this.graphLabels,
      },
    ];
  }

  private get options() {
    return {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "bottom",
          },
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: Number,
      },
      xaxis: {
        type: "string",
        categories: this.graphDataX,
      },
      legend: {
        show: false,
        position: "right",
        offsetY: 40,
      },
      colors: ["#1390d8", "transparent"],
      dataLabels: {
        enabledOnSeries: [1],
        style: {
          colors: ["#000"],
        },
      },
      title: {
        text: "Keg Yields",
        align: "left",
        margin: 30,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "20px",
          fontWeight: 500,
          fontFamily: "Roboto",
          color: "#263238",
        },
      },
    };
  }
}
