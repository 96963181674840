






import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  PintsPerHourGraphDataType,
  PintsPerHourGraphModel,
} from "@/api/generated";
import moment from "moment";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

@Component
export default class DeploymentPintsPerHourGraph extends Vue {
  @Prop(String) private readonly deploymentId!: string;
  @Prop(Array) pintsPerHour: Array<Array<PintsPerHourGraphModel>> = [];
  @PropSync("title", { type: String, default: "" })
  public syncedTitle!: string;
  @Prop(Number) dataType!: PintsPerHourGraphDataType;
  @PropSync("filterTitle", { type: String, default: "" })
  public syncedFilterTitle!: string;

  pintsPerHourGraphDataType = PintsPerHourGraphDataType;

  private get series() {
    let result: any[] = [];

    if (this.dataType === this.pintsPerHourGraphDataType.NUMBER_0) {
      result = [
        {
          name: this.filterName,
          data:
            this.pintsPerHour && this.pintsPerHour.length
              ? this.pintsPerHour[0].map((x) => x.orderedDrinks)
              : [],
        },
      ];
    } else {
      if (this.pintsPerHour && this.pintsPerHour.length) {
        for (let index = 0; index < this.pintsPerHour.length; index++) {
          result.push({
            name: this.pintsPerHour[index][0].seriesName,
            data: this.pintsPerHour[index].map((m) => m.orderedDrinks),
          });
        }
      }
    }
    return result;
  }

  private get filterName() {
    if (this.syncedFilterTitle == "interval") {
      return "Rate";
    } else if (this.syncedFilterTitle == "revenue") {
      return "Sales";
    } else {
      return "Units";
    }
  }

  private get graphTitle() {
    if (this.syncedFilterTitle == "interval") {
      return "Rate (pints per hour)";
    } else if (this.syncedFilterTitle == "revenue") {
      return "Sales (£)";
    } else if (this.syncedFilterTitle == "sales") {
      return "Cumulative Sales";
    } else if (this.syncedFilterTitle == "avgRate") {
      return "Average Rate";
    } else {
      return "Units sold";
    }
  }

  private get options(): ApexOptions {
    return {
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      chart: {
        height: "auto",
        type: "line",

        toolbar: {
          tools: {
            pan: false,
            selection: false,
            zoom: true,
          },
          export: {
            png: {
              filename: `${this.graphTitle}`,
            },
            svg: {
              filename: `${this.graphTitle}`,
            },
            csv: {
              filename: `${this.graphTitle}`,
            },
          },
        },
      },

      markers: {
        size: 3,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      colors: [
        "#ff0000",
        "#0000FF",
        "#00FF00",
        "#FFFF00",
        "#FFA500",
        "#A020F0",
        "#FFC0CB",
        "#964B00",
        "#000000",
        "#808080",
        "#f5f5dc",
        "#8F00FF",
        "#B0BF1A",
        "#D0FF14",
        "#9C2542",
        "#EEDC82",
        "#96C8A2",
        "#B48395",
        "#1E90FF",
        "#483D8B",
        "#B8860B",
        "#AD6F69",
        "#8C92AC",
        "#A2A2D0",
        "#E4D00A",
      ],
      xaxis: {
        categories:
          this.pintsPerHour && this.pintsPerHour.length
            ? this.pintsPerHour[0].map((x) =>
                moment.utc(x.minute).utc().local().format("HH:mm")
              )
            : [], //need to figure out how to deal with a single category axis (X-axis) minutes array to work with multiple data series (Y-axis) arrays.
        labels: {
          show: true,
          datetimeUTC: true,
          format: "date",
          hideOverlappingLabels: true,
          trim: true,
          rotate: -70,
          rotateAlways: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
      },
      title: {
        text: `${this.graphTitle}`,
        align: "left",
        margin: 30,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "20px",
          fontWeight: 500,
          fontFamily: "Roboto",
          color: "#263238",
        },
      },
    };
  }
}
