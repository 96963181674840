






























import { Vue, Component } from "vue-property-decorator";
import DeploymentTable from "@/components/deployment/DeploymentTable.vue";
import { DeploymentModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import CreateDeploymentDialog from "@/components/dialogs/deployment/CreateDeploymentDialog.vue";
import UpdateDeploymentDialog from "@/components/dialogs/deployment/UpdateDeploymentDialog.vue";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    DeploymentTable,
    CreateDeploymentDialog,
    UpdateDeploymentDialog,
  },
})
export default class DeploymentOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private deleteDialog = false;
  private selectedDeploymentId = "";
  private items: Array<DeploymentModel> = [];

  private async created() {
    await this.getDeployments();
  }

  private get adminOnsiteUserPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private get portfolioManagerPermissions() {
    return userModule.userRole == userRoleConstant.porfolioManager;
  }

  private async getDeployments() {
    try {
      if (this.portfolioManagerPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByorganisationOrganisationIdGet(
            userModule.organisationId
          );
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve deployments");
    }
  }

  private onUpdateDeployment(item: DeploymentModel) {
    if (!item.deploymentId) {
      return;
    }

    this.selectedDeploymentId = item.deploymentId;
    this.updateDialog = true;
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "DeploymentProfile",
      params: { deploymentId: item.deploymentId },
    });
  }

  private async archiveDeployment(item: DeploymentModel) {
    if (!item.deploymentId) {
      return;
    }

    this.selectedDeploymentId = item.deploymentId;

    try {
      await api.DeploymentService.apiDeploymentArchiveDeploymentIdDelete(
        this.selectedDeploymentId
      );
      snackbarModule.setSnackbarMessage("Deployment successfully archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive deployment");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
  private async unarchiveDeployment(item: DeploymentModel) {
    if (!item.deploymentId) {
      return;
    }

    this.selectedDeploymentId = item.deploymentId;

    try {
      await api.DeploymentService.apiDeploymentUnarchiveDeploymentIdDelete(
        this.selectedDeploymentId
      );
      snackbarModule.setSnackbarMessage("Deployment successfully unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive deployment");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }

  private async onDeleteRequest(deployment: DeploymentModel) {
    if (!deployment.deploymentId) {
      return;
    }
    this.selectedDeploymentId = deployment.deploymentId;
    this.deleteDialog = true;
  }

  private async onDeleteDeployment() {
    if (!this.selectedDeploymentId) {
      return;
    }
    try {
      await api.DeploymentService.apiDeploymentDeploymentIdDelete(
        this.selectedDeploymentId
      );
      snackbarModule.setSnackbarMessage(`Deployment successfully deleted`);
      this.tableRefreshKey = !this.tableRefreshKey;
      this.$emit("deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete deployment");
    }
  }
}
