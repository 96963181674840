






































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const userModule = getModule(UserModule);

@Component
export default class BaseDataTable extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "Create" }) private btnText!: string;
  @Prop({ type: Boolean, default: false }) private noSearch!: boolean;
  @Prop({ type: Boolean, default: false }) private noCreate!: boolean;
  @Prop({ type: Boolean, default: false }) private endedDeployments!: boolean;
  @Prop({ type: Boolean, default: false }) private noCard!: boolean;

  private search = "";

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }
}
