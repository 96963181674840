import axios, { AxiosRequestConfig } from "axios";
import userModule from "@/store/userModule";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import environment from "@/environment";
import { TokenModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

const configuration: AxiosRequestConfig = {
  baseURL: environment.API_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
  },
};

export const apiClient = axios.create(configuration);

// We need a seperate client to retrieve files as we need to set
// response type to blob
export const fileClient = axios.create({
  ...configuration,
  responseType: "blob",
});

const bearerInterceptor = (request: AxiosRequestConfig) => {
  const tokenJson = localStorage.getItem("user");

  if (!tokenJson) {
    return request;
  }

  const user = JSON.parse(tokenJson) as TokenModel;

  if (user.accessToken) {
    // this is sometimes undefined??
    request.headers["Authorization"] = `Bearer ${user.accessToken}`;
  }
  return request;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const retryInterceptor = async (error: any) => {
  const response = error.response;
  const module = getModule(userModule);
  if (response) {
    if (response.status === 401 && error.config && !error.config._retry) {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          const userModel = JSON.parse(user) as TokenModel;
          await module.refreshToken(userModel);
        }
      } catch (authError) {
        return Promise.reject(error);
      }
      error.config._retry = true;
      return await apiClient.request(error.config);
    }
  }

  return Promise.reject(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const snackbarInterceptor = (error: any) => {
  // Don't show snackbar if we're refreshing our token due to a 401
  if (error.config._retry) {
    return Promise.reject(error);
  }
  // Error handlings
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.Message
  ) {
    snackbarModule.setSnackbarMessage(`${error.response.data.Message}`);
  } else if (error && error.response && error.response.data) {
    snackbarModule.setSnackbarMessage(error.response.data);
  } else {
    snackbarModule.setSnackbarMessage(error.message);
  }

  return Promise.reject(error);
};

// Apply interceptors to both clients
apiClient.interceptors.request.use(bearerInterceptor);
fileClient.interceptors.request.use(bearerInterceptor);

// following outline from https://gist.github.com/bragma/f68391596de71e1bfae066be80c259dc
// eslint-disable-next-line @typescript-eslint/no-explicit-any
apiClient.interceptors.response.use(undefined, retryInterceptor);
fileClient.interceptors.response.use(undefined, retryInterceptor);

apiClient.interceptors.response.use(function (response) {
  // Pass through nothing to see here (2xx resopnse)
  return response;
}, snackbarInterceptor);

fileClient.interceptors.response.use(function (response) {
  // Pass through nothing to see here (2xx resopnse)
  return response;
}, snackbarInterceptor);

export default {
  getBaseURL(): string | undefined {
    return apiClient.defaults.baseURL;
  },
};
