




































import { Component, Prop, Ref } from "vue-property-decorator";
import Login from "@/views/Login.vue";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { PasswordResetModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { Login } })
export default class PasswordReset extends Validation {
  @Ref() readonly form!: VForm;
  @Prop(String) private passwordResetToken!: string;

  private confirmPassword = "";
  private loading = false;
  private model: PasswordResetModel = {
    password: "",
  };

  get getPasswordsMatch(): boolean | string {
    return this.model.password == this.confirmPassword || "Password must match";
  }

  private async onResetPassword() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.AuthService.apiAuthorisationResetPasswordIdPost(
        this.passwordResetToken,
        this.model
      );
      snackbarModule.setSnackbarMessage("Successfully reset password");
      this.$router.replace({ name: "Login" });
    } catch {
      snackbarModule.setSnackbarMessage("Failed to reset password");
    } finally {
      this.loading = false;
    }
  }
}
