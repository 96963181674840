









import { Vue, Component, VModel, Prop } from "vue-property-decorator";

@Component
export default class BaseColorPicker extends Vue {
  @VModel({ default: "#000000" }) private color!: string;
  @Prop(String) private topLabel!: string;
}
